function SimpleCard({ card }: any) {
    return (
        <div className='flex flex-col items-center'>
            <img
                src={`${card.icon}`}
                alt={`${card.altIcon}`}
                width={100}
                height={100}
                className='w-[50px] h-[50px] md:w-[100px] md:h-[100px]'
            />
            <div className="w-[280px] text-center text-zinc-800 text-[15px] font-normal font-['Roboto Regular'] mt-[10px] lg:mt-[45px] md:text-[20px] md:w-[300px]">{card.text}</div>
        </div>
    )
}

export default SimpleCard