function Footer() {
    return (
        <div className="w-[100%] h-[209px] bg-blue-500 flex flex-col justify-center items-center">
            <div className="text-white text-base font-normal font-['Roboto Regular']">Síguenos v2</div>
            <div className='flex gap-[40px] md:gap-[70px] mt-[20px]'>

                <a href={'https://www.instagram.com/zonaindependiente_oficial/'}>
                    <img
                        className='cursor-pointer'
                        src="../icons/instagram.svg"
                        alt="Instagam"
                        width={30}
                        height={30}
                    />
                </a>

                <a href={'https://www.facebook.com/Zindependiente/'}>
                    <img
                        className='cursor-pointer'
                        src="../icons/facebook.svg"
                        alt="Facebook"
                        width={29}
                        height={29}
                    />
                </a>

                <a href={'https://twitter.com/Zindependiente'}>
                    <img
                        className='cursor-pointer'
                        src="../icons/x.svg"
                        alt="X"
                        width={28}
                        height={28}
                    />
                </a>


                <a href={'https://tiktok.com/@Zonaindependiente'}>
                    <img
                        className='cursor-pointer'
                        src="../icons/tiktok.svg"
                        alt="TikTok"
                        width={30}
                        height={30}
                    />
                </a>
            </div>
        </div>
    )
}

export default Footer