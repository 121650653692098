
import { useQuery, gql } from '@apollo/client';

function VerifyEmail({ uuid }: any) {

    const getEmailVerification = gql`
    query ValidateEmail($uuid: String){
        validateEmail (uuid: $uuid) {
            status
        }
    }
    `;

    const { loading, error } = useQuery(getEmailVerification, {
        variables: { uuid }
    });

    const styles = 'p-2 text-center text-gray-800 text-[20px] font-normal font-[\'Roboto Regular\']';

    if (loading) return <h1 className={styles}>Cargando ...</h1>
    if (error) return (
        <div className='flex flex-col items-center mt-[100px]'>
            <img
                src="../icons/warning.svg"
                alt="success"
                width={106}
                height={106}
            />
            <h1 className={styles}>{error.message === 'ALREADY EXISTS' ? 'El correo ha sido verificado anteriormente' : 'Ha ocurrido un error, intentalo luego'}</h1>
        </div>
    )

    return (
        <div className='flex flex-col items-center mt-[100px]'>
            <img
                src="../icons/success.svg"
                alt="success"
                width={106}
                height={106}
            />
            <div className={styles}>Has verificado tu correo de forma satisfactoria, por favor continua en la aplicación</div>
        </div>
    )
}


export default VerifyEmail