import { useSearchParams } from 'react-router-dom'
import Landing from './Landing'
import VerifyEmail from './VerifyEmail'



function Home() {
  const [searchParams] = useSearchParams();
  const verifyEmail = searchParams.get('verifyEmail');

  return verifyEmail ? <VerifyEmail uuid={verifyEmail} /> : <Landing />;
}

export default Home