export class SimpleCardDto {
    text: string;
    icon: string;
    altIcon: string;

    constructor(text: string, icon: string, altIcon: string) {
        this.text = text;
        this.icon = icon;
        this.altIcon = altIcon;
    }
}