import React from 'react'
import SimpleCard from '../components/SimpleCard'
import { SimpleCardDto } from '../dto/componentsDto'
import Footer from '../components/Footer'

function Landing() {
    return (
        <div >
            <div className="h-screen w-[100%] bg-blue-500 mt-200">
                <div className='h-[calc(100%-150px)] flex justify-center md:justify-between px-[10px] md:px-[70px]'>
                    <div className='flex flex-col justify-center items-center text-center md:text-start md:items-start'>
                        <h1 className="max-w-[500px] text-white text-[45px] font-medium font-['Roboto Medium'] md:text-[70px]">Zona Independiente</h1>
                        <div className="max-w-[373px] text-white text-[30px] font-normal font-['Roboto Regular'] mt-[44px]">El lugar de los profesionales del día a día </div>
                        <div className="w-[199px] h-[50px] bg-white rounded-[20px] flex justify-center items-center mt-[44px] cursor-pointer">
                            <div className="text-center text-blue-950 text-base font-medium font-['Roboto Medium']">Descarga la app</div>
                        </div>
                    </div>

                    <div className='flex justify-center items-center max-[900px]:hidden'>
                        <img
                            src="../images/mainMockup.svg"
                            alt="Vercel Logo"
                            width={250}
                            height={494.32}
                        />
                    </div>
                </div>
            </div>
            <div className='px-[20px] py-[30px] md:px-[70px] md:py-[98px]'>
                <div className="text-center text-blue-950 text-[30px] font-medium font-['Roboto Medium'] leading-10 md:text-[40px]">Somos la herramienta que necesitas</div>
                <div className="text-center text-zinc-800 text-[20px] font-normal font-['Roboto Regular'] mt-[20px] md:mt-[58px] md:text-[30px]">Queremos facilitar el contacto entre los especialistas en trabajos y quienes necesitan dichos servicios. Con nosotros podrás:</div>

                <div className='flex justify-between mt-[50px] flex-col lg:flex-row md:mt-[88px] gap-[55px] lg:gap-[10px]'>
                    <SimpleCard card={new SimpleCardDto(
                        'Buscar la persona adecuada para resolver el trabajo que necesitas que alguien haga por ti',
                        '../icons/find.svg',
                        'magnifying glass')}  ></SimpleCard>

                    <SimpleCard card={new SimpleCardDto(
                        'Conocer a las personas cercanas que se dedican a brindar el servicio que necesitas',
                        '../icons/worker.svg',
                        'worker')}  ></SimpleCard>

                    <SimpleCard card={new SimpleCardDto(
                        'Recomendar a tus conocidos aquella persona que te ayudó recientemente',
                        '../icons/like.svg',
                        'like')}  ></SimpleCard>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Landing