import React from 'react'

function Privacidad() {
    const paragraph = "text-[16px] font-normal font-['Roboto Regular'] mt-[10px]";
    const subtitle = "mt-[20px] text-[18px] font-semibold font-['Roboto Medium']"
    return (
        <div className='px-[30px] py-[50px] md:px-[140px]'>
            <div className="text-center text-[20px] font-bold font-['Roboto Medium']">Política de Privacidad</div>
            <div className={`${paragraph} mt-[20px]`}>Última actualización: 23 de abril de 2024 <br /> <br />
                Zona independiente respeta la privacidad de nuestros usuarios ("usuario" o "tú") y se compromete a proteger la información personal que puedas proporcionarnos a través de nuestra aplicación móvil Zona independiente<br /><br />
                Esta Política de Privacidad describe cómo recopilamos, utilizamos, compartimos y protegemos tu información personal cuando utilizas nuestra aplicación móvil Zona independiente (la "Aplicación").
            </div>
            <div className={subtitle}>
                1. Información que Recopilamos <br />
                1.1. Información que nos proporcionas
            </div>

            <div className={paragraph} >
                Cuando te registras en nuestra aplicación, podemos solicitar cierta información personal, como tu nombre, dirección de correo electrónico, apellido, número de teléfono de contacto y ocupación. Esta información se utiliza para identificarte como usuario registrado y permitirte publicar ofertas dentro de la aplicación.
            </div>

            <div className={subtitle}>
                1.2. Información recopilada automáticamente
            </div>

            <div className={paragraph} >
                Además de la información que nos proporcionas directamente, podemos recopilar cierta información automáticamente cuando utilizas nuestra aplicación. Esta información puede incluir tu dirección IP, tipo de dispositivo, identificadores únicos del dispositivo, tipo de navegador, páginas visitadas, información de ubicación y otra información de registro.
            </div>

            <div className={subtitle}>
                2. Uso de la Información
            </div>

            <div className={paragraph} >
                <ul>
                    <ol >Utilizamos la información que recopilamos, incluida la información personal, para los siguientes fines:</ol>
                    <li >
                        Facilitar el registro y la autenticación de usuarios en nuestra aplicación.
                    </li>
                    <li>
                        Permitir a los usuarios publicar ofertas dentro de la aplicación.
                    </li>

                    <li>
                        Proporcionar y mantener nuestra aplicación.
                    </li>

                    <li>
                        Personalizar y mejorar tu experiencia con nuestra aplicación.
                    </li>

                    <li>
                        Comprender y analizar cómo interactúas con nuestra aplicación.
                    </li>

                    <li>
                        Cumplir con las leyes y regulaciones aplicables.
                    </li>
                </ul>
            </div>

            <div className={subtitle}>
                3. Compartir de la Información
            </div>

            <div className={paragraph} >
                <ul>
                    <ol >No compartimos la información personal de nuestros usuarios con terceros, excepto en las siguientes circunstancias:</ol>
                    <li >
                        Con tu consentimiento.
                    </li>
                    <li>
                        Cuando sea necesario para cumplir con las leyes y regulaciones aplicables.
                    </li>

                    <li>
                        Cuando sea necesario para proteger los derechos, la propiedad o la seguridad de nuestra aplicación, nuestros usuarios u otros.
                    </li>
                </ul>
            </div>

            <div className={subtitle}>
                4. Seguridad de la Informaciónn
            </div>

            <div className={paragraph} >
                Nos comprometemos a proteger la información personal de nuestros usuarios. Utilizamos medidas de seguridad razonables para proteger la información contra pérdida, uso indebido, acceso no autorizado, divulgación, alteración o destrucción.
            </div>

            <div className={subtitle}>
                5. Tus Derechos y Opciones
            </div>

            <div className={paragraph} >
                Puedes acceder, corregir, actualizar o eliminar tu información personal en cualquier momento a través de la configuración de tu cuenta dentro de nuestra aplicación. También puedes optar por no recibir comunicaciones promocionales nuestras siguiendo las instrucciones proporcionadas en dichas comunicaciones.
            </div>

            <div className={subtitle}>
                6. Cambios en esta Política de Privacidad
            </div>

            <div className={paragraph} >
                Nos reservamos el derecho de actualizar o cambiar nuestra Política de Privacidad en cualquier momento. Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.
            </div>

            <div className={subtitle}>
                7. Contacto
            </div>

            <div className={paragraph} >
                Si tienes alguna pregunta sobre esta Política de Privacidad, contáctanos en zonindependiente@gmail.com
            </div>
        </div>
    )
}

export default Privacidad