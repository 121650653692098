import { createBrowserRouter } from "react-router-dom";
import Home from '../pages/Home'
import Privacidad from "../pages/Privacidad";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/privacidad",
        element: <Privacidad />,
    }
]);