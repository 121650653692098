function Navbar() {
    return (
        <div className='flex items-center bg-blue-500 py-[25px] px-[10px] md:px-[70px] justify-between'>
            <a href="/">
                <div className="flex items-center cursor-pointer">
                    <img
                        src="../icons/ziCircular.svg"
                        alt="Vercel Logo"
                        width={50}
                        height={50}
                    />
                    <div className="text-white text-2xl font-medium font-['Montserrat Alternates']">ona independiente</div>
                </div>
            </a>
        </div>
    )
}

export default Navbar